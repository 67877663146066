<!-- eslint-disable no-unused-vars -->
<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <ul id="masterInfoAssets" class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button id="wait-master-info-assets" class="nav-link active" data-bs-toggle="tab"
              data-bs-target="#waitMasterInfoAssets" type="button" role="tab" aria-controls="waitMasterInfoAssets"
              aria-selected="true" @click="onClickTab('1')">
              {{ $t('content.reviewasset') }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="show-master-info-assets" class="nav-link" data-bs-toggle="tab"
              data-bs-target="#showMasterInfoAssets" type="button" role="tab" aria-controls="showMasterInfoAssets"
              aria-selected="false" @click="onClickTab('2')">
              {{ $t('content.manageassettk') }}
            </button>
          </li>

          <li class="nav-item" role="presentation" v-show="false">
            <button id="action-master-info-assets" class="nav-link" data-bs-toggle="tab"
              data-bs-target="#actionMasterInfoAssets" type="button" role="tab" aria-controls="actionMasterInfoAssets"
              aria-selected="false" @click="onClickTab('3')">
              {{ $t('content.manageasset') }}
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button id="priority-assets" class="nav-link" data-bs-toggle="tab" data-bs-target="#priorityAssets"
              type="button" role="tab" aria-controls="actionPriorityAssets" aria-selected="false"
              @click="onClickTab('4')">
              {{ $t('content.mcatorder') }}
            </button>
          </li>
        </ul>

        <div id="masterInfoAssetsContent" class="tab-content">
          <div id="waitMasterInfoAssets" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="wait-master-info-assets">
            <div class="row h-100">
              <div class="col-12">
                <div class="card extra mt-2">
                  <div class="card-header mb-0">
                    <h5>{{ $t('content.reviewasset') }}</h5>
                    <span id='status'>{{ loadMoreStatus }}</span>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row h-100">
                      <div id="inpc" class="table-responsive">
                        <table class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th></th>
                              <th>#</th>
                              <th>{{ $t('content.addassetdate') }}</th>
                              <th>{{ $t('content.type') }}1</th>
                              <th>{{ $t('content.type') }}2</th>
                              <th>{{ $t('content.type') }}3</th>
                              <th>{{ $t('content.type') }}4</th>
                              <th>{{ $t('content.type') }}5</th>
                              <th>{{ $t('content.type') }}6</th>
                              <th>{{ $t('content.act') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="group-id-ticket">
                              <td class="text-sm font-weight-normal" colspan="2">
                                <div class="group-tickect text-end">
                                  <b>{{ btnSearch }}</b>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal" colspan="6">
                                <div ref="parentRef" class="cs-auto-input">
                                  <input id="inp" v-model="filterText" ref="input" placeholder="ค้นหาประเภททรัพย์..."
                                    class="form-control auto-input-search" list="assetOptions" @input="formatinput"
                                    autocomplete="off" type="search">
                                  <ul id="myList" v-if="dataList.length > 0 && filterText.length > 0" ref="list"
                                    class="data-list" :style="`width: ${childWidth}px !important;`"
                                    @scroll="handleScroll">
                                    <li v-for="(item, index) in dataList" :key="index" @click="selection(item)">
                                      <a>{{ item.datailt }}</a>
                                    </li>
                                    <!-- <li v-if="loading">Loading...</li> -->
                                    <p v-if='dataList.length' id='load-more' :class='loadMoreStatus'></p>
                                  </ul>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal" colspan="1">
                                <label>{{ textt }}</label>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-button color="primary" variant="outline" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnAddNew"
                                  @click="addNewItems">
                                  <i class="material-icons material-symbols-outlined">add</i>
                                </material-button>
                                <material-button v-if="datas.addNew" color="info" variant="contain"
                                  class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top"
                                  :title="btnCopy" :disabled="checkBeforeCopy(
                                    datas.newBox1,
                                    datas.newCopy
                                  )
                                    ">
                                  <i class="material-icons material-symbols-outlined">content_copy</i>
                                </material-button>
                              </td>
                            </tr>

                            <tr v-if="datas.addNew">
                              <td class="text-sm font-weight-normal"></td>
                              <td class="text-sm font-weight-normal">#</td>
                              <td class="text-sm font-weight-normal">
                                xx/xx/xxxx
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-input id="mia-type1" type="text" class="mb-0" value="xxxxx" />
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-input id="mia-type2" type="text" class="mb-0" value="xxxxx" />
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-input id="mia-type3" type="text" class="mb-0" value="xxxxx" />
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-input id="mia-type4" type="text" class="mb-0" value="xxxxx" />
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-input id="mia-type5" type="text" class="mb-0" value="xxxxx" />
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-input id="mia-type6" type="text" class="mb-0" value="xxxxx" />
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-button color="primary" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                                  @click="cancelNewItems">
                                  <i class="material-icons material-symbols-outlined">save</i>
                                </material-button>
                                <material-button color="warning" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                                  @click="cancelNewItems">
                                  <i class="material-icons material-symbols-outlined">close</i>
                                </material-button>
                              </td>
                            </tr>

                            <tr v-if="datas.removeCompare">
                              <td class="text-sm font-weight-normal">
                                <material-radio class="m-0" :checked="!datas.addNew ? false : true" />
                              </td>
                              <td class="text-sm font-weight-normal">1</td>
                              <td class="text-sm font-weight-normal">
                                xx/xx/xxxx
                              </td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal"></td>
                              <td class="text-sm font-weight-normal"></td>
                              <td class="text-sm font-weight-normal">
                                <material-button color="primary" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnPreview"
                                  @click="onLookupDetail">
                                  <i class="material-icons material-symbols-outlined">attach_file</i>
                                </material-button>
                                <material-button color="danger" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                                  :disabled="!datas.addNew ? false : true">
                                  <i class="material-icons material-symbols-outlined">delete</i>
                                </material-button>
                              </td>
                            </tr>

                            <tr>
                              <td class="text-sm font-weight-normal">
                                <material-radio class="m-0" />
                              </td>
                              <td class="text-sm font-weight-normal">2</td>
                              <td class="text-sm font-weight-normal">
                                xx/xx/xxxx
                              </td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal">xxxxx</td>
                              <td class="text-sm font-weight-normal"></td>
                              <td class="text-sm font-weight-normal"></td>
                              <td class="text-sm font-weight-normal">
                                <material-button color="primary" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnPreview"
                                  @click="onLookupDetail">
                                  <i class="material-icons material-symbols-outlined">attach_file</i>
                                </material-button>
                                <material-button color="danger" variant="outline" class="rounded-circle"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                  <i class="material-icons material-symbols-outlined">delete</i>
                                </material-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="showMasterInfoAssets" class="tab-pane fade" role="tabpanel"
            aria-labelledby="show-master-info-assets">
            <div class="card h-100 mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.manageassettk') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.type') }}1</th>
                          <th>{{ $t('content.type') }}2</th>
                          <th>{{ $t('content.type') }}3</th>
                          <th>{{ $t('content.type') }}4</th>
                          <th>{{ $t('content.type') }}5</th>
                          <th>{{ $t('content.type') }}6</th>
                          <th>{{ $t('content.weight') }}</th>
                          <!-- <th>{{ $t('content.act') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>

                        <tr v-if="addNewp">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <material-input id="mia-type1" v-model="namep" type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp2" :checked="tp2" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp3" :checked="tp3" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp4" :checked="tp4" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp5" :checked="tp5" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp6" :checked="tp6" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="rew" :checked="rew" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="namep.trim() === ''" @click="saveNewItemsPrint">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelShowItemsPrint">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-for="(row, index) in listprint" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ index + 1 }}
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-input v-model="namep" type="text" class="mb-0" disabled />
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp2" :checked="tp2" />
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp3" :checked="tp3" />
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp4" :checked="tp4" />
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp5" :checked="tp5" />
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-checkbox v-model="tp6" :checked="tp6" />
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-checkbox v-model="rew" :checked="rew" />
                          </td>
                          <td v-if="row.uid === uidp" class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="namep.trim() === ''" @click="saveEditRowItemsPrint()">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelEditRowItemsPrint">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                          <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            {{ row.name }}
                          </td>
                          <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            <material-checkbox :checked="row.l2Print" :disabled="true" />
                          </td>
                          <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            <material-checkbox :checked="row.l3Print" :disabled="true" />
                          </td>
                          <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            <material-checkbox :checked="row.l4Print" :disabled="true" />
                          </td>
                          <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            <material-checkbox :checked="row.l5Print" :disabled="true" />
                          </td>
                          <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            <material-checkbox :checked="row.l6Print" :disabled="true" />
                          </td>
                          <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            <material-checkbox :checked="row.reqWeight" :disabled="true" />
                          </td>
                          <!-- <td v-if="row.uid !== uidp" class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editRowItemsPrint(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteRowItemsPrint(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td> -->
                        </tr>

                        <tr v-if="listprint.length === 0">
                          <td></td>
                          <td></td>
                          <td></td>

                          <td class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="actionMasterInfoAssets" class="tab-pane fade" role="tabpanel"
            aria-labelledby="action-master-info-assets">
            <!-- <div class="row h-100"> -->
            <!-- <div class="card extra mt-2"> -->
            <div class="card mt-2">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5>{{ $t('content.manageasset') }}</h5>
                    <div>
                      <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-0"
                        @click="addCoreItems">
                        <i class="material-icons material-symbols-outlined">add</i>
                        {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <!-- <div class="row h-100"> -->
                <div class="row">
                  <div class="table-responsive" style="min-height:300px">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.type') }}1</th>
                          <th>{{ $t('content.type') }}2</th>
                          <th>{{ $t('content.type') }}3</th>
                          <th>{{ $t('content.type') }}4</th>
                          <th>{{ $t('content.type') }}5</th>
                          <th>{{ $t('content.type') }}6</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal">
                            <div class="group-tickect">
                              <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">

                            <!-- <select v-model="tc1_inp" class="form-select" data-live-search="true">
                                <option v-for="(a, corea) in listcat1" :value="a.uid" :key="corea">
                                  {{ a.name }}
                                </option>
                              </select> -->



                            <div class="input-group">
                              <input v-model="datas.coreBox1" type="text" class="form-control" disabled />
                              <button class="btn btn-outline-primary dropdown-toggle" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false"
                                :disabled="!listcat1_new.length > 0"></button>
                              <ul class="dropdown-menu dropdown-menu-end" style="height: 100%">
                                <li>
                                  <div class="input-group">
                                    <input v-model="tc1_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                      autocomplete="off" @:keyup="selectCatTypeList('1', tc1_inp)" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li v-for="(a, corea) in listcat1_new" :key="corea" :value="corea" @click="checktc1(a)">
                                  <a v-if="a.uid !== tc1.uid" class="dropdown-item">
                                    <!-- <material-checkbox v-model="a.usable" :checked="a.usable" class="check-inline"
                                      :disabled="a.uid !== tc1.uid && tc1 !== ''" @change="checktc1(a)" /> -->
                                    {{ a.name }}</a>
                                  <a v-if="a.uid === tc1.uid" class="dropdown-item active bg-primary text-white">
                                    <!-- <material-checkbox v-model="a.usable" :checked="a.usable" class="check-inline"
                                      :disabled="a.uid !== tc1.uid && tc1 !== ''" @change="checktc1(a)" /> -->
                                    {{ a.name }}</a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <div class="input-group">
                              <input v-model="datas.coreBox2" type="text" class="form-control" disabled />
                              <button v-show="listcat2_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                :disabled="!listcat2_new.length > 0"></button>
                              <ul class="dropdown-menu dropdown-menu-end" style="height: 100%">
                                <li>
                                  <div class="input-group">
                                    <input v-model="tc2_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                      autocomplete="off" @:keyup="selectCatTypeList('2', tc2_inp)" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li v-for="(b, coreb) in listcat2_new" :key="coreb" :value="coreb" @click="checktc2(b)">
                                  <a v-if="b.uid !== tc2.uid" class="dropdown-item">
                                    <!-- <material-checkbox v-model="b.usable" :checked="b.usable" class="check-inline"
                                      :disabled="b.uid !== tc2.uid && tc2 !== ''" @change="checktc2(b)" /> -->
                                    {{ b.name }}
                                  </a>
                                  <a v-if="b.uid === tc2.uid" class="dropdown-item active bg-primary text-white">
                                    <!-- <material-checkbox v-model="b.usable" :checked="b.usable" class="check-inline"
                                      :disabled="b.uid !== tc2.uid && tc2 !== ''" @change="checktc2(b)" /> -->
                                    {{ b.name }}
                                  </a>
                                </li>

                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <div class="input-group">
                              <input v-model="datas.coreBox3" type="text" class="form-control" disabled />
                              <button v-show="listcat3_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                :disabled="!listcat3_new.length > 0"></button>
                              <ul class="dropdown-menu dropdown-menu-end" style="height: 100%">
                                <li>
                                  <div class="input-group">
                                    <input v-model="tc3_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                      autocomplete="off" @:keyup="selectCatTypeList('3', tc3_inp)" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li v-for="(c, corec) in listcat3_new" :key="corec" :value="corec" @click="checktc3(c)">
                                  <a v-if="c.uid !== tc3.uid" class="dropdown-item">
                                    <!-- <material-checkbox v-model="c.usable" :checked="c.usable" class="check-inline"
                                      :disabled="c.uid !== tc3.uid && tc3 !== ''" @change="checktc3(c)" /> -->
                                    {{ c.name }}</a>
                                  <a v-if="c.uid === tc3.uid" class="dropdown-item active bg-primary text-white">
                                    <!-- <material-checkbox v-model="c.usable" :checked="c.usable" class="check-inline"
                                      :disabled="c.uid !== tc3.uid && tc3 !== ''" @change="checktc3(c)" /> -->
                                    {{ c.name }}</a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <div class="input-group">
                              <input v-model="datas.coreBox4" type="text" class="form-control" disabled />
                              <button v-show="listcat4_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                :disabled="!listcat4_new.length > 0"></button>
                              <ul class="dropdown-menu dropdown-menu-end" style="height: 100%">
                                <li>
                                  <div class="input-group">
                                    <input v-model="tc4_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                      autocomplete="off" @:keyup="selectCatTypeList('4', tc4_inp)" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li v-for="(d, cored) in listcat4_new" :key="cored" :value="cored" @click="checktc4(d)">
                                  <a v-if="d.uid !== tc4.uid" class="dropdown-item">
                                    <!-- <material-checkbox v-model="d.usable" :checked="d.usable" class="check-inline"
                                      :disabled="d.uid !== tc4.uid && tc4 !== ''" @change="checktc4(d)" /> -->
                                    {{ d.name }}</a>
                                  <a v-if="d.uid === tc4.uid" class="dropdown-item active bg-primary text-white">
                                    <!-- <material-checkbox v-model="d.usable" :checked="d.usable" class="check-inline"
                                      :disabled="d.uid !== tc4.uid && tc4 !== ''" @change="checktc4(d)" /> -->
                                    {{ d.name }}</a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <div class="input-group">
                              <input v-model="datas.coreBox5" type="text" class="form-control" disabled />
                              <button v-show="listcat5_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                :disabled="!listcat5_new.length > 0"></button>
                              <ul class="dropdown-menu dropdown-menu-end" style="height: 100%">
                                <li>
                                  <div class="input-group">
                                    <input v-model="tc5_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                      autocomplete="off" @:keyup="selectCatTypeList('5', tc5_inp)" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li v-for="(e, coree) in listcat5_new" :key="coree" :value="coree" @click="checktc5(e)">
                                  <a v-if="e.uid !== tc5.uid" class="dropdown-item">
                                    <!-- <material-checkbox v-model="e.usable" :checked="e.usable" class="check-inline"
                                      :disabled="e.uid !== tc5.uid && tc5 !== ''" @change="checktc5(e)" /> -->
                                    {{ e.name }}</a>
                                  <a v-if="e.uid === tc5.uid" class="dropdown-item active bg-primary text-white">
                                    <!-- <material-checkbox v-model="e.usable" :checked="e.usable" class="check-inline"
                                      :disabled="e.uid !== tc5.uid && tc5 !== ''" @change="checktc5(e)" /> -->
                                    {{ e.name }}</a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <div class="input-group">
                              <input v-model="datas.coreBox6" type="text" class="form-control" disabled />
                              <button v-show="listcat6_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                :disabled="!listcat6_new.length > 0"></button>
                              <ul class="dropdown-menu dropdown-menu-end" style="height: 100%">
                                <li>
                                  <div class="input-group">
                                    <input v-model="tc6_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                      autocomplete="off" @:keyup="selectCatTypeList('6', tc6_inp)" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li v-for="(f, coref) in listcat6_new" :key="coref" :value="coref" @click="checktc6(f)">
                                  <a v-if="f.uid !== tc6.uid" class="dropdown-item">
                                    <!-- <material-checkbox v-model="f.usable" :checked="f.usable" class="check-inline"
                                        :disabled="f.uid !== tc6.uid && tc6 !== ''" @change="checktc6(f)" /> -->
                                    {{ f.name }}</a>
                                  <a v-if="f.uid === tc6.uid" class="dropdown-item active bg-primary text-white">
                                    <!-- <material-checkbox v-model="f.usable" :checked="f.usable" class="check-inline"
                                        :disabled="f.uid !== tc6.uid && tc6 !== ''" @change="checktc6(f)" /> -->
                                    {{ f.name }}</a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              @click="loadDataCategoryApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="addNewc">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <material-input id="mia-new-type1" v-model="tc1_inpA" type="text" class="mb-0"
                              :disabled="catlv_add !== '1'" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input id="mia-new-type2" v-model="tc2_inpA" type="text" class="mb-0"
                              :disabled="catlv_add !== '2'" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input id="mia-new-type3" v-model="tc3_inpA" type="text" class="mb-0"
                              :disabled="catlv_add !== '3'" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input id="mia-new-type4" v-model="tc4_inpA" type="text" class="mb-0"
                              :disabled="catlv_add !== '4'" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input id="mia-new-type5" v-model="tc5_inpA" type="text" class="mb-0"
                              :disabled="catlv_add !== '5'" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input id="mia-new-type6" v-model="tc6_inpA" type="text" class="mb-0"
                              :disabled="catlv_add !== '6'" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="tc1_inpA.trim() === '' && tc2_inpA.trim() === '' && tc3_inpA.trim() === '' && tc4_inpA.trim() === '' && tc5_inpA.trim() === '' && tc6_inpA.trim() === ''"
                              @click="saveNewCoreItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewCoreItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-for="(row, index) in listcatData" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}
                          </td>
                          <td v-if="row.uid === uidc" class="text-sm font-weight-normal">
                            <material-input v-model="tc1_inpA" type="text" class="mb-0" />
                          </td>
                          <td v-if="row.uid !== uidc" class="text-sm font-weight-normal">
                            {{ row.name }}
                          </td>
                          <td class="text-sm font-weight-normal">
                          </td>
                          <td class="text-sm font-weight-normal">
                          </td>
                          <td class="text-sm font-weight-normal">
                          </td>
                          <td class="text-sm font-weight-normal">
                          </td>
                          <td class="text-sm font-weight-normal">
                          </td>


                          <td v-if="row.uid === uidc" class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="tc1_inpA.trim() === ''" @click="saveEditRowCoreItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="closeCoreItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                          <td v-if="row.uid !== uidc" class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editRowCoreItems(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnView"><i
                                class="material-icons material-symbols-outlined" @click="enableCoreRowItems(row)">{{
                                  !row.enable ? "visibility_off" : "visibility"
                                }}</i>
                            </material-button>
                            <material-button :color="(row.usable ? 'success' : 'dark')" variant="outline"
                              class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnUse"><i
                                class="material-icons material-symbols-outlined" @click="useableCoreRowItems(row)">{{
                                  row.usable
                                    ? 'check' :
                                    'close' }}</i>
                            </material-button>

                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteCoreRowItems(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="listcatData.length === 0">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="8" style="text-align: right">Total Rows : {{ totalRowsC_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="listcatData.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                          :items-per-page="perPageC" @click="onClickHandlerC" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div id="priorityAssets" class="tab-pane fade" role="tabpanel" aria-labelledby="priority-assets">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5>{{ $t('content.mcatorder') }}</h5>
                    <div class="col-9 d-flex justify-content-end align-items-center">
                      <div class="me-3">
                        <material-button id="saveCurrOrder" color="primary" class="float-lg-start float-sm-end mb-3"
                          @click="saveOrderItems()">
                          <i class="material-icons material-symbols-outlined">save</i>
                          {{ btnSave }}
                        </material-button>
                      </div>
                      <div class="me-3">
                        <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                          @click="loadDataOrderCatApi()">
                          <i class="material-icons material-symbols-outlined">close</i>
                          {{ btnCancel }}
                        </material-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div id="simple-list" class="row">
                    <ol id="example1" class="list-group list-group-numbered">
                      <li class="list-group-item" v-for="(index) in listcat_ordernew" :key="index" :data-id="index">
                        {{ filterCat(index) }}
                      </li>
                    </ol>
                  </div>
                </div>
                <pre></pre>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, computed, inject, onMounted, ref, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";

import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import { useShopCatOrderStore } from '@/stores/shopcatorder'
import { useCategoryStore } from '@/stores/category'

const storec = useCategoryStore()
const storeco = useShopCatOrderStore()


const { t } = useI18n();
const swal = inject("$swal");

const datas = reactive({
  addNew: false,
  newCopy: true,
  newBox1: "",
  removeCompare: true,
  coreEdit: false,
  coreAdd: false,
  coreOn: true,
  coreCopy: true,
  coreBox1: "",
  coreBox2: "",
  coreBox3: "",
  coreBox4: "",
  coreBox5: "",
  coreBox6: "",
  demo1: [
    { items: "ประเภท1.1" },
    { items: "ประเภท1.2" },
    { items: "ประเภท1.3" },
    { items: "ประเภท1.4" },
    { items: "ประเภท1.5" },
    { items: "ประเภท1.6" },
  ],
  demo2: [
    { items: "ประเภท2.1" },
    { items: "ประเภท2.2" },
    { items: "ประเภท2.3" },
    { items: "ประเภท2.4" },
    { items: "ประเภท2.5" },
    { items: "ประเภท2.6" },
  ],
  demo3: [
    { items: "ประเภท3.1" },
    { items: "ประเภท3.2" },
    { items: "ประเภท3.3" },
    { items: "ประเภท3.4" },
    { items: "ประเภท3.5" },
    { items: "ประเภท3.6" },
  ],
  demo4: [
    { items: "ประเภท4.1" },
    { items: "ประเภท4.2" },
    { items: "ประเภท4.3" },
    { items: "ประเภท4.4" },
    { items: "ประเภท4.5" },
    { items: "ประเภท4.6" },
  ],
  demo5: [
    { items: "ประเภท5.1" },
    { items: "ประเภท5.2" },
    { items: "ประเภท5.3" },
    { items: "ประเภท5.4" },
    { items: "ประเภท5.5" },
    { items: "ประเภท5.6" },
  ],
  demo6: [
    { items: "ประเภท6.1" },
    { items: "ประเภท6.2" },
    { items: "ประเภท6.3" },
    { items: "ประเภท6.4" },
    { items: "ประเภท6.5" },
    { items: "ประเภท6.6" },
  ],
  demo7: [
    { items: computed(() => t("content.amngold")) },
    { items: computed(() => t("content.amndiamond")) },
    { items: computed(() => t("content.amnframe")) },
    { items: computed(() => t("content.amncopper")) },
    { items: computed(() => t("content.amnwatch")) },
    { items: computed(() => t("content.amnothers")) },
  ],
});

const tempA = reactive([
  {
    t1: computed(() => t("content.amngold")),
    t2: false,
    t3: true,
    t4: true,
    t5: false,
    t6: false,
  },
  {
    t1: computed(() => t("content.amndiamond")),
    t2: true,
    t3: true,
    t4: true,
    t5: false,
    t6: false,
  },
  {
    t1: computed(() => t("content.amnframe")),
    t2: true,
    t3: true,
    t4: true,
    t5: true,
    t6: true,
  },
  {
    t1: computed(() => t("content.amnwatch")),
    t2: true,
    t3: true,
    t4: true,
    t5: false,
    t6: false,
  },
  {
    t1: computed(() => t("content.amncopper")),
    t2: false,
    t3: true,
    t4: true,
    t5: false,
    t6: false,
  },
  {
    t1: computed(() => t("content.amnothers")),
    t2: true,
    t3: true,
    t4: true,
    t5: true,
    t6: true,
  },
]);

const btnPreview = computed(() => t("content.file"));
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))
const btnView = computed(() => t("content.viewable"))
const btnUse = computed(() => t("content.useable"))
const btnAddNew = computed(() => t("buttons.impo"))
const btnCopy = computed(() => t("content.copyData"))
const btnCancel = computed(() => t("buttons.canc"));

const checkBeforeCopy = (box, bool) => {
  // datas.coreCopy = bool
  // if (datas.coreBox1 === '') {
  if (box === "") {
    bool = true;
  } else {
    bool = false;
  }
  return bool;
};

const filterText = ref("");
const filterInput = ref("");
const typesNew = ref([]);
const selected = ref("");
const limit = 20;
const page_s = ref(0);
const page_e = ref(20);
const dataList = ref([]);
const textt = ref("");
const Listdata = ref([]);
const types = ref([]);
const parentWidth = ref(0);
const childWidth = ref(0);

//infinite scroll
var input = ref < HTMLInputElement | null > (null)
const loadMoreStatus = ref('idle');
const parentRef = ref(null);
const list = ref(null);
const total = ref('')
const loading = ref(false);
const showSuggestions = ref(false);

const listprint = ref([]);
const addNewp = ref(false);
const rowEditp = ref(false);
const uidp = ref("");
const namep = ref("");
const versionp = ref("");
const tp1 = ref(false);
const tp2 = ref(false);
const tp3 = ref(false);
const tp4 = ref(false);
const tp5 = ref(false);
const tp6 = ref(false);
const rew = ref(false);
const namepfilter = ref("");

const listcat1 = ref([]);
const listcat2 = ref([]);
const listcat3 = ref([]);
const listcat4 = ref([]);
const listcat5 = ref([]);
const listcat6 = ref([]);
const typefilter = ref("");
const tc1 = ref("");
const tc2 = ref("");
const tc3 = ref("");
const tc4 = ref("");
const tc5 = ref("");
const tc6 = ref("");
const tc1_inp = ref("");
const tc2_inp = ref("");
const tc3_inp = ref("");
const tc4_inp = ref("");
const tc5_inp = ref("");
const tc6_inp = ref("");
const tc1_inpA = ref("");
const tc2_inpA = ref("");
const tc3_inpA = ref("");
const tc4_inpA = ref("");
const tc5_inpA = ref("");
const tc6_inpA = ref("");
const listcatData = ref([]);
const addNewc = ref(false);
const rowEditc = ref(false);
const uidc = ref("");
const uidc_2 = ref("");
const versionc = ref("");
const useablec = ref("");
const enablec = ref("");
const listcat1_new = ref([]);
const listcat2_new = ref([]);
const listcat3_new = ref([]);
const listcat4_new = ref([]);
const listcat5_new = ref([]);
const listcat6_new = ref([]);
const listcat_chk = ref([]);
const uid_chk = ref("");
const catlv_add = ref("");
const catlv_update = ref("");

const onClickHandlerC = (page) => {
  // console.log('onClickHandler :' + page);
  updatePaginationCat()
};
const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRowsC_s = ref('0');


const listcat_all = ref([]);
const listcat_order = ref([]);
const listcat_ordernew = ref([]);
const listcat = ref([]);
const sortable = ref(null);

const catorder_detail = ref({
  uid: '',
  version: '',
  catOrders: {},
  shopUid: '',
})

const key = JSON.parse(localStorage.getItem("shop_seleted"));

const loadDataApi = async () => {
  // await new Promise((res) => setTimeout(res, 500));
  let data = "";
  data = {
    uid: 0,
  };
  const payload = data;
  // const response = await store.dispatch("categoryfullNameFetch", payload);
  const response = await storec.categoryfullNameFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        Listdata.value = response.data.data.list;
        // console.log('Listdata : '+JSON.stringify(response.data.data.list))
        if (Listdata.value.length > 0) {
          // console.log('newData : '+JSON.stringify(Listdata.value.length))
          types.value = [];
          checkdataMatch2(Listdata.value);
        }
      }
    }
  }
};

loadDataApi();

const loadDataPrintApi = async () => {
  // await new Promise((res) => setTimeout(res, 500));
  clearItemsPrint();
  let data = "";
  data = {
    uid: "",
  };
  const payload = data;
  // const response = await store.dispatch("categorylistPrintFetch", payload);
  const response = await storec.categorylistPrintFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listprint.value = response.data.data;
        // console.log('Listdata : '+JSON.stringify(response.data.data.list))
      }
    }
  }
};



const loadDataCoreApi = async () => {
  // await new Promise((res) => setTimeout(res, 500));
  // clearItemsPrint();
  let data = "";
  data = {
    index: 0,
    size: 100,
    uid: "null",
  };
  const payload = data;
  // const response = await store.dispatch("categoryListFetch", payload);
  const response = await storec.categoryListFetch(payload);
  if (response) {
    if (response.status === 200) {
      // console.log('listcat1 : ' + JSON.stringify(response.data))
      if (response.data.rtnDesc === "success") {
        let list = response.data.data.list;

        list.map(function (x) {
          x.usable = false;
          return x
        });
        listcat1.value = list
        listcat1_new.value = listcat1.value
        // console.log('listcat1 : ' + JSON.stringify(listcat1.value))
      }
    }
  }
};



// Fetch data function
const fetchData = async () => {
  try {
    loading.value = true;
    await new Promise(res => setTimeout(res, 500));
    // console.log('typesNew fetch : ' + JSON.stringify(typesNew.value))
    const res = typesNew.value.slice(page_s.value, page_e.value);
    // console.log('res fetch : ' + JSON.stringify(res))
    dataList.value.push(...res);
    loadMoreStatus.value = res.length < limit ? "no-more" : "idle";
    page_s.value = page_s.value + res.length;
    page_e.value = page_s.value + limit;
    showSuggestions.value = true;

    let re = String(res.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    let ret = String(dataList.value.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    total.value = ' result : ' + re + ' / ' + ret
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false;
  }
};

// Handle scroll event for infinite loading
const handleScroll = () => {
  const scrollContainer = list.value;
  if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
    if (dataList.value.length <= 20) {
      // console.log("data : " + dataList.value.length);
      scrollContainer.scrollTo(0, 0);
    }

    if (typesNew.value.length > 0) {
      loadMoreStatus.value = "loading";
      fetchData();
    }
    // Reached the bottom of the scroll container, load more data
    // page.value++;
    // if (typesNew.value.length > 0) {
    //   fetchData();
    // }

  }
};

// Function to update widths
const updateWidths = () => {
  if (parentRef.value && list.value) {
    parentWidth.value = parentRef.value.clientWidth;
    childWidth.value = parentRef.value.clientWidth;
  }
}


/* eslint-disable no-useless-escape */
const updateText = () => {
  let inp = document.querySelector("#inp");
  let list = document.querySelectorAll('#myList li')
  // console.log("updated() list : " + list.length);
  var filtter = inp.value.trim().replaceAll(">>", " ");
  var text = filtter.toLowerCase().replaceAll("'", " ");
  var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
  var repl = text.replace(invalid, "")
  var arrt = repl.match(/\S+/g);

  if (arrt !== null) {
    const arr = Array.from(list);
    arr.forEach((x) => {
      // if (re.test(x.textContent)) {
      const txtValue = x.innerText || x.textContent;
      var string = txtValue;

      var finalAns = string;
      for (var i = arrt.length - 1; i >= 0; i--) {
        finalAns = finalAns.replace(
          RegExp(arrt[i], "gi"),
          '<b style="color:orange;">$&</b>'
        );
      }
      x.innerHTML = finalAns;
      x.style.display = "block";
    });
  }
}

async function onClickTab(val) {
  if (val === '1') {
    dataList.value = []
  } else if (val === '2') {
    loadDataPrintApi();
  } else if (val === '3') {
    loadDataCoreApi();
  } else if (val === '4') {
    loadDataAllCatApi();
  }
}


async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("ยืนยันการชำระสำเร็จ", "", "success")
      datas.addNew = true;
      console.log(datas.addNew);
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการเพิ่มรายการนี้", "", "error");
      datas.addNew = false;
      console.log(datas.addNew);
    }
  });
}

async function cancelNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "บันทึก",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success");
      datas.addNew = false;
      datas.removeCompare = false;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกบันทึกรายการ", "", "error");
      datas.addNew = false;
      datas.removeCompare = true;
    }
  });
}

async function cancelShowItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "บันทึก",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success");
      datas.addNew = false;
      datas.removeCompare = false;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกบันทึกรายการ", "", "error");
      datas.addNew = false;
      datas.removeCompare = true;
    }
  });
}

async function editRowCoreItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsCoreAE()
      addNewc.value = false;
      rowEditc.value = true;
      uidc.value = item.uid;
      versionc.value = item.version;
      tc1_inpA.value = item.name
      useablec.value = item.usable
      enablec.value = item.enable
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการแก้ไขรายการนี้", "", "error");
      rowEditc.value = false;
    }
  });
}

async function closeCoreItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "บันทึก",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      rowEditc.value = false;
      clearItemsCoreAE()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกบันทึกและแก้ไขรายการ", "", "error");
      rowEditc.value = true;
    }
  });
}

async function addCoreItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsCoreAE()
      addCategoryLevel()
      addNewc.value = true;
      rowEditc.value = false;
    } else if (result.dismiss == "cancel") {
      addNewc.value = false;
    }
  });
}

async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      "</section>" +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      "</div>",
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

// eslint-disable-next-line no-unused-vars
async function checkdataMatch2(list) {
  let list2 = [];
  let t1 = "";
  let t2 = "";
  let t3 = "";
  let t4 = "";
  let t5 = "";
  let t6 = "";

  for (let i = 0; i < list.length; i++) {
    t1 = "";
    t2 = "";
    t3 = "";
    t4 = "";
    t5 = "";
    t6 = ''

    let text = list[i].fullName;
    text = text.split(">>");
    let count = text.length - 1;
    // console.log('text sett : '+text)

    if (count > 0) {
      if (count === 1) {
        t1 = text[0];
        t2 = text[1];
        // ss = text[1] +" >> " +text[2]
      } else if (count === 2) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        // ss = text[1] +" >> " +text[2] +" >> " +text[3]
        // ss = ss +" >> " +text[2]
      } else if (count === 3) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        t4 = text[3];
        // ss = text[1] +" >> " +text[2] +" >> " +text[3] +" >> " +text[4]
      } else if (count === 4) {
        t1 = text[0];
        t2 = text[1];
        t3 = text[2];
        t4 = text[3];
        t5 = text[4];
        // ss = text[1] +" >> " +text[2] +" >> " +text[3] +" >> " +text[4] +" >> " +text[5]
      } else if (count === 5) {
        // console.log('data sett : '+list[i].fullName)
        t1 = text[0]
        t2 = text[1]
        t3 = text[2]
        t4 = text[3]
        t5 = text[4]
        t6 = text[5]
      }
    }
    // console.log('data sett : '+list[i].fullName.replaceAll (">>", " "))
    const obj = {
      uid: list[i].uid,
      detailt: list[i].fullName.replaceAll(">>", " "),
      fullName: list[i].fullName,
      datailt: list[i].fullName,
      code: list[i].code,
      t1: t1,
      t2: t2,
      t3: t3,
      t4: t4,
      t5: t5,
      t6: t6,
    };
    list2.push(obj);
  }

  // console.log('data sett : '+JSON.stringify(list2))
  textt.value = "";
  if (list2.length > 0) {
    types.value = list2;
  }
}

async function selection(value) {
  selected.value = value;
  filterText.value = value.datailt;
  typesNew.value = [];
  textt.value = "";
  loadMoreStatus.value = "idle";
  dataList.value = [];
  //   console.log('input : '+JSON.stringify(this.selected))
}

async function formatinput() {
  filterInput.value = "";
  page_s.value = 0;
  page_e.value = 20;
  typesNew.value = [];
  loadMoreStatus.value = "idle";
  dataList.value = [];
  // console.log('filterText : '+this.filterText.trim().length)
  // this.filterInput = this.filterText.replace(/^\s+|\s+$/gm,'')
  if (filterText.value.trim().length > 0) {
    if (filterText.value.includes(">>")) {
      let text = filterText.value.replaceAll(/\s/g, "");
      text = text.split(">>");
      let count = text.length - 1;

      // console.log('xx text : '+text[0] + 'text L : '+text.length + ' count : '+count)

      if (count > 0) {
        if (count === 1) {
          var list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .includes(text[1].toLowerCase())
          );
          // this.typesNew = list
          checkdataMatch(list);

          // console.log('1 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 2) {
          // console.log('2 text[0]  : '+text[0] + ' text[1]  : '+text[1] + ' text[2]  : '+text[2])

          const list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3
                .replaceAll(/\s/g, "")
                .toLowerCase()
                .includes(text[2].toLowerCase())
          );
          // this.typesNew = list
          checkdataMatch(list);

          // console.log('2 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 3) {
          const list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t4.toLowerCase().includes(text[3].toLowerCase())
          );
          // this.typesNew =  list
          checkdataMatch(list);

          // console.log('3 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 4) {
          const list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[3].toLowerCase() &&
              c.t5.toLowerCase().includes(text[4].toLowerCase())
          );
          // this.typesNew =  list
          checkdataMatch(list);

          // console.log('3 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        } else if (count === 5) {
          // console.log('count 5')
          const list = types.value.filter(
            (c) =>
              c.t1.toLowerCase().replaceAll(/\s/g, "") ===
              text[0].toLowerCase() &&
              c.t2.toLowerCase().replaceAll(/\s/g, "") ===
              text[1].toLowerCase() &&
              c.t3.toLowerCase().replaceAll(/\s/g, "") ===
              text[2].toLowerCase() &&
              c.t4.toLowerCase().replaceAll(/\s/g, "") ===
              text[3].toLowerCase() &&
              c.t5.toLowerCase().replaceAll(/\s/g, "") ===
              text[4].toLowerCase() &&
              c.t6.toLowerCase().includes(text[5].toLowerCase())
          );
          // this.typesNew =  list
          checkdataMatch(list);

          // console.log('3 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
        }
      } else {
        filterInput.value = filterText.value.replaceAll(">>", "");
        const list = types.value.filter(
          (c) =>
            c.t1.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t2.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t3.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t4.toLowerCase().includes(filterInput.value.toLowerCase()) ||
            c.t5.toLowerCase().includes(filterInput.value.toLowerCase())
        );
        // this.typesNew =  list
        checkdataMatch(list);

        // console.log('4 text : '+text + 'text L : '+text.length + ' count : '+count + ' typesNew L : '+JSON.stringify(this.typesNew))
      }
    } else {
      if (
        filterText.value !== "" &&
        filterText.value !== undefined &&
        filterText.value !== null
      ) {
        // console.log('ft : '+this.filterText)

        filterInput.value = filterText.value.replaceAll(">>", "");
        // let stringArray = []
        // stringArray = this.filterInput.trim().toLowerCase().match(/\S+/g)
        var text = filterInput.value.trim().toLowerCase();
        var arr = text.match(/\S+/g);

        if (arr !== null) {
          // console.log('ft : ' + arr)
          const stringIncludes = types.value.filter((c) =>
            allAvailable(c.detailt.toLowerCase(), arr)
          );
          //  console.log('5 typesNew L : '+stringIncludes.length)
          checkdataMatch(stringIncludes);
        }
      }
    }
  } else {
    typesNew.value = [];
    textt.value = "";
  }
}

async function checkdataMatch(list) {
  let list2 = [];
  for (let i = 0; i < list.length; i++) {
    const obj = {
      datailt: list[i].datailt,
      t1: list[i].t1,
      t2: list[i].t2,
      t3: list[i].t3,
      t4: list[i].t4,
      t5: list[i].t5,
      t6: list[i].t6,
      detailt: list[i].detailt,
      fullName: list[i].fullName,
      uid: list[i].uid,
      code: list[i].code
    };
    list2.push(obj);
  }

  textt.value = "";
  if (list2.length > 0) {
    // console.log('list2 : '+list2.length)
    typesNew.value = list2;
    // let re = String(list2.length).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    // let ret = String(types.value.length).replace(
    //   /(\d)(?=(\d{3})+(?!\d))/g,
    //   "$1,"
    // );
    textt.value = "result : " + formatNumberString(list2.length) + " / " + formatNumberString(types.value.length);
    fetchData()
    // loadData();
    // console.log("item : " + typesNew.value.length);
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function selectCatTypeList(num, inp) {

  if (num === '1') {
    listcat1_new.value = filterMatch(inp, listcat1.value)
  } else if (num === '2') {
    listcat2_new.value = filterMatch(inp, listcat2.value)
  } else if (num === '3') {
    listcat3_new.value = filterMatch(inp, listcat3.value)
  } else if (num === '4') {
    listcat4_new.value = filterMatch(inp, listcat4.value)
  } else if (num === '5') {
    listcat5_new.value = filterMatch(inp, listcat5.value)
  } else if (num === '6') {
    listcat6_new.value = filterMatch(inp, listcat6.value)
  }
}


function allAvailable(str, matchArr) {
  // console.log('str : '+str + ' / matchArr : ' +matchArr)
  return matchArr.every((item) => str.includes(item));
}

function filterMatch(str, matchArr) {
  // console.log('str : '+str + ' / matchArr : ' +matchArr)
  return matchArr.filter((c) => c.name.toLowerCase().includes(str.toLowerCase()));
}



async function addNewItemsPrint() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      addNewp.value = true;
      rowEditp.value = false;
      uidp.value = "";
      namep.value = namepfilter.value;
      versionp.value = "";
      tp1.value = false;
      tp2.value = true;
      tp3.value = true;
      tp4.value = true;
      tp5.value = true;
      tp6.value = true;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการเพิ่มรายการนี้", "", "error");
      addNewp.value = false;
    }
  });
}

async function saveNewItemsPrint() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewp.value = false;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการบันทึก", "", "error");
      addNewp.value = false;
      clearItemsPrint();
    }
  });
}

async function cancelShowItemsPrint() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "บันทึก",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success");
      addNewp.value = false;
      datas.removeCompare = false;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกบันทึกรายการ", "", "error");
      addNewp.value = false;
      datas.removeCompare = true;
    }
  });
}

async function saveEditRowItemsPrint(item) {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      rowEditp.value = false;
      if (uidp.value !== "" && versionp.value !== "") {
        // console.log('update item : ' + tp6.value);
        updateprint();
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกแก้ไข", "", "error");
      rowEditp.value = false;
      clearItemsPrint();
    }
  });
}

async function cancelEditRowItemsPrint() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEditp.value = false;
      clearItemsPrint();
    } else if (result.dismiss == "cancel") {
      rowEditp.value = true;
    }
  });
}

async function editRowItemsPrint(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewp.value = false;
      rowEditp.value = true;
      uidp.value = item.uid;
      namep.value = item.name;
      versionp.value = item.version;
      tp1.value = false;
      tp2.value = item.l2Print;
      tp3.value = item.l3Print;
      tp4.value = item.l4Print;
      tp5.value = item.l5Print;
      tp6.value = item.l6Print;
      rew.value = item.reqWeight;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      // datas.rowEdit = false;
      rowEditp.value = false;
      clearItemsPrint();
    }
  });
}

async function deleteRowItemsPrint(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      uidp.value = item.uid;
      if (uidp.value !== "") {
        clearItemsPrint();
      }
    }
  });
}

async function updateprint() {
  let data = "";
  data = {
    uid: uidp.value,
    name: namep.value,
    l2Print: tp2.value,
    l3Print: tp3.value,
    l4Print: tp4.value,
    l5Print: tp5.value,
    l6Print: tp6.value,
    reqWeight: rew.value,
    version: versionp.value,
  };
  const payload = data;
  // console.log('updateprint : ' + JSON.stringify(payload));
  // const response = await store.dispatch("categoryUpdatePrintFetch", payload);
  const response = await storec.categoryUpdatePrintFetch(payload);
  // console.log('updateprint : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataPrintApi();
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function clearItemsPrint() {
  addNewp.value = false;
  rowEditp.value = false;
  uidp.value = "";
  namep.value = "";
  versionp.value = "";
  tp1.value = true;
  tp2.value = true;
  tp3.value = true;
  tp4.value = true;
  tp5.value = true;
  tp6.value = true;
  rew.value = true;
  namepfilter.value = "";
}

async function clearItemsCoreAll() {

  addNewc.value = false;
  rowEditc.value = false;
  uidc.value = "";
  versionc.value = "";
  uidc_2.value = "";

  tc1.value = ""
  tc2.value = ""
  tc3.value = ""
  tc4.value = ""
  tc5.value = ""
  tc6.value = ""

  tc1_inp.value = ""
  tc2_inp.value = ""
  tc3_inp.value = ""
  tc4_inp.value = ""
  tc5_inp.value = ""
  tc6_inp.value = ""

  listcat1.value = []
  listcat2.value = []
  listcat3.value = []
  listcat4.value = []
  listcat5.value = []
  listcat6.value = []


  listcat1_new.value = []
  listcat2_new.value = []
  listcat3_new.value = []
  listcat4_new.value = []
  listcat5_new.value = []
  listcat6_new.value = []

}


async function clearItemsCoreAE() {
  addNewc.value = false;
  rowEditc.value = false;
  uidc.value = "";
  versionc.value = "";
  tc1_inpA.value = ""
  tc2_inpA.value = ""
  tc3_inpA.value = ""
  tc4_inpA.value = ""
  tc5_inpA.value = ""
  tc6_inpA.value = ""
  uidc_2.value = "";
}

function addCategoryLevel() {

  catlv_add.value = '1';

  if (tc1.value !== '') {
    catlv_add.value = '2';
  }

  if (tc2.value !== '') {
    catlv_add.value = '3';
  }

  if (tc3.value !== '') {
    catlv_add.value = '4';
  }

  if (tc4.value !== '') {
    catlv_add.value = '5';
  }

  if (tc5.value !== '') {
    catlv_add.value = '6';
  }

  if (tc6.value !== '') {
    catlv_add.value = '6';
  }

}

function findCategoryLevel() {

  uid_chk.value = "null";
  catlv_update.value = '1'

  if (tc1.value !== '') {
    uid_chk.value = tc1.value.uid
    catlv_update.value = '2'
  }

  if (tc2.value !== '') {
    uid_chk.value = tc2.value.uid
    catlv_update.value = '3'
  }

  if (tc3.value !== '') {
    uid_chk.value = tc3.value.uid
    catlv_update.value = '4'
  }

  if (tc4.value !== '') {
    uid_chk.value = tc4.value.uid
    catlv_update.value = '5'
  }

  if (tc5.value !== '' || tc6.value !== '') {
    uid_chk.value = tc5.value.uid
    catlv_update.value = '6'
  }
}



async function loadDataCategoryApi(val) {

  clearItemsCoreAE()
  findCategoryLevel();

  if (currentPageC.value !== 1) {
    if (listcatData.value.length === 1 && val === 'del') {
      currentPageC.value = currentPageC.value - 1
    }
  }

  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    uid: uid_chk.value,
  };
  const payload = data;
  // const response = await store.dispatch("categoryListFetch", payload);
  const response = await storec.categoryListFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcatData.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRowsC_s.value = formatNumberString(totalRowsC.value)
        // console.log('Listdata : '+JSON.stringify(response.data.data.list))
      }
    }
  }
}

async function updatePaginationCat() {

  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    uid: uid_chk.value,
  };
  const payload = data;
  // const response = await store.dispatch("categoryListFetch", payload);
  const response = await storec.categoryListFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcatData.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRowsC_s.value = formatNumberString(totalRowsC.value)
        // console.log('Listdata : '+JSON.stringify(response.data.data.list))
      }
    }
  }
}

async function deleteCoreRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการ " + "' " + item.name + " ' นี้ใช่ไหม ",
    icon: "warning",
    input: "text",
    inputLabel: "กรุณาพิมพ์ " + "' " + item.name + " ' เพื่อลบรายการ",
    inputAttributes: {
      "size": "50",
      "autocomplete": "off"
    },
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value === item.name) {
          resolve();

          if (item.uid !== "") {
            deletecore(item.uid);
          }
        } else {
          resolve("ข้อความที่พิมพ์ไม่ตรงกัน :)");
        }
      });
    }
  });
}

async function enableCoreRowItems(item) {
  swal({
    title: "แก้ไขเปิด/ปิดการมองเห็นรายการ",
    text: "คุณต้องการแก้ไขเปิด/ปิดการมองเห็นรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      uidc.value = "";
      uidc_2.value = item.uid;
      versionc.value = item.version;
      tc1_inpA.value = item.name
      useablec.value = item.usable
      console.log('item enable : ' + JSON.stringify(item))
      if (item.enable) {
        enablec.value = false
      } else {
        enablec.value = true
      }
      if (uidc_2.value !== "") {
        updatecore();
      }
    }
  });
}

async function useableCoreRowItems(item) {
  swal({
    title: "แก้ไขเปิด/ปิดการใช้งานรายการ",
    text: "คุณต้องการแก้ไขเปิด/ปิดการใช้งานรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      uidc.value = "";
      uidc_2.value = item.uid;
      versionc.value = item.version
      tc1_inpA.value = item.name
      enablec.value = item.enable
      // console.log('item useable : ' + JSON.stringify(item))
      if (item.usable) {
        useablec.value = false
      } else {
        useablec.value = true
      }
      if (uidc_2.value !== "") {
        updatecore();
      }
    }
  });
}

async function saveNewCoreItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewc.value = false;
      addcore();
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการบันทึก", "", "error");
      addNewc.value = false;
      clearItemsCoreAE();
    }
  });
}

async function cancelSaveNewCoreItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewc.value = false;
      clearItemsCoreAE();
    } else if (result.dismiss == "cancel") {
      addNewc.value = true;
    }
  });
}

async function saveEditRowCoreItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEditc.value = false;
      if (uidc.value !== "" && versionc.value !== "") {
        updatecore();
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกแก้ไข", "", "error");
      rowEditc.value = false;
      clearItemsCoreAE()
    }
  });
}

async function addcore() {
  let data = "";
  if (catlv_add.value === '1') {
    data = {
      name: tc1_inpA.value,
      usable: false,
      parentUid: "0",
      enable: true
    };
  } else if (catlv_add.value === '2') {
    data = {
      name: tc2_inpA.value,
      usable: false,
      parentUid: uid_chk.value,
      enable: true
    };
  } else if (catlv_add.value === '3') {
    data = {
      name: tc3_inpA.value,
      usable: false,
      parentUid: uid_chk.value,
      enable: true
    };
  } else if (catlv_add.value === '4') {
    data = {
      name: tc4_inpA.value,
      usable: false,
      parentUid: uid_chk.value,
      enable: true
    };
  } else if (catlv_add.value === '5') {
    data = {
      name: tc5_inpA.value,
      usable: false,
      parentUid: uid_chk.value,
      enable: true
    };
  } else if (catlv_add.value === '6') {
    data = {
      name: tc6_inpA.value,
      usable: false,
      parentUid: uid_chk.value,
      enable: true
    };
  }

  const payload = data;
  // console.log('addcore pl : ' + JSON.stringify(payload));
  const response = await storec.categoryAddFetch(payload);
  // console.log('addcore res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataCore('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updatecore() {
  let data = "";
  let parentUid = uid_chk.value
  let uid = ""
  if (catlv_update.value === '1') {
    parentUid = "0"
  }
  if (uidc.value !== '') {
    uid = uidc.value
  } else {
    uid = uidc_2.value
  }
  data = {
    uid: uid,
    level: catlv_update.value,
    name: tc1_inpA.value,
    usable: useablec.value,
    enable: enablec.value,
    parentUid: parentUid,
    version: versionc.value,
  };
  const payload = data;
  // console.log('updatecore pl : ' + JSON.stringify(payload));
  const response = await storec.categoryUpdateFetch(payload);
  // console.log('updatecore res : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataCore('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deletecore(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  // console.log('deletecore pl : ' + JSON.stringify(payload));
  // const response = await store.dispatch("categoryDeleteFetch", payload);
  const response = await storec.categoryDeleteFetch(payload);
  // console.log('deletecore res : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataCore('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function loadDataCore(val) {

  // listcatData.value = []
  await loadDataCategoryApi(val)
  if (catlv_update.value === '1') {
    await getcatType(uid_chk.value)
    listcat1.value = listcat_chk.value
    if (tc1_inp.value !== '') {
      listcat1_new.value = filterMatch(tc1_inp, listcat1.value)
    } else {
      listcat1_new.value = listcat1.value
    }


  } else if (catlv_update.value === '2') {
    await getcatType(uid_chk.value)
    listcat2.value = listcat_chk.value
    if (tc2_inp.value !== '') {
      listcat2_new.value = filterMatch(tc2_inp, listcat2.value)
    } else {
      listcat2_new.value = listcat2.value
    }

  } else if (catlv_update.value === '3') {
    await getcatType(uid_chk.value)
    listcat3.value = listcat_chk.value
    if (tc3_inp.value !== '') {
      listcat3_new.value = filterMatch(tc3_inp, listcat3.value)
    } else {
      listcat3_new.value = listcat3.value
    }

  } else if (catlv_update.value === '4') {
    await getcatType(uid_chk.value)
    listcat4.value = listcat_chk.value
    if (tc4_inp.value !== '') {
      listcat4_new.value = filterMatch(tc4_inp, listcat4.value)
    } else {
      listcat4_new.value = listcat4.value
    }

  } else if (catlv_update.value === '5') {
    await getcatType(uid_chk.value)
    listcat5.value = listcat_chk.value
    if (tc5_inp.value !== '') {
      listcat5_new.value = filterMatch(tc5_inp, listcat5.value)
    } else {
      listcat4_new.value = listcat4.value
    }
  } else if (catlv_update.value === '6') {
    await getcatType(uid_chk.value)
    listcat6.value = listcat_chk.value
    if (tc6_inp.value !== '') {
      listcat6_new.value = filterMatch(tc6_inp, listcat6.value)
    } else {
      listcat6_new.value = listcat6.value
    }

  }






}


async function getcatType(uid) {
  listcat_chk.value = []
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    uid: uid,
  };
  const payload = data;
  // const response = await store.dispatch("categoryListFetch", payload);
  const response = await storec.categoryListFetch(payload);
  if (response) {
    if (response.status === 200) {
      // console.log('listcat : ' + JSON.stringify(response.data))
      if (response.data.rtnDesc === "success") {
        let list = response.data.data.list;
        list.map(function (x) {
          x.usable = false;
          return x
        });
        listcat_chk.value = list
        // console.log('listcat : ' + JSON.stringify(list))
        // console.log('listcat1 : ' + JSON.stringify(listcat1.value))
      }
    }
  }
}


async function checktc1(item) {
  if (item !== tc1.value) {
    // } else {
    datas.coreBox1 = ''
    datas.coreBox2 = ''
    datas.coreBox3 = ''
    datas.coreBox4 = ''
    datas.coreBox5 = ''
    datas.coreBox6 = ''

    tc1.value = ""
    tc2.value = ""
    tc3.value = ""
    tc4.value = ""
    tc5.value = ""
    tc6.value = ""

    tc2_inp.value = ""
    tc3_inp.value = ""
    tc4_inp.value = ""
    tc5_inp.value = ""
    tc6_inp.value = ""

    listcat2.value = []
    listcat3.value = []
    listcat4.value = []
    listcat5.value = []
    listcat6.value = []

    listcat2_new.value = []
    listcat3_new.value = []
    listcat4_new.value = []
    listcat5_new.value = []
    listcat6_new.value = []

    tc1.value = item
    listcat2.value = []
    listcat2_new.value = []
    await getcatType(item.uid)
    listcat2.value = listcat_chk.value
    listcat2_new.value = listcat2.value
    datas.coreBox1 = item.name
    // console.log('tc1 false : ' + JSON.stringify(tc1.value));
  }
}

async function checktc2(item) {

  if (item !== tc2.value) {

    // console.log('tc1 true : ' + JSON.stringify(tc1.value));
    // } else {
    datas.coreBox2 = ''
    datas.coreBox3 = ''
    datas.coreBox4 = ''
    datas.coreBox5 = ''
    datas.coreBox6 = ''

    tc2.value = ""
    tc3.value = ""
    tc4.value = ""
    tc5.value = ""
    tc6.value = ""

    tc3_inp.value = ""
    tc4_inp.value = ""
    tc5_inp.value = ""
    tc6_inp.value = ""

    listcat3.value = []
    listcat4.value = []
    listcat5.value = []
    listcat6.value = []

    listcat3_new.value = []
    listcat4_new.value = []
    listcat5_new.value = []
    listcat6_new.value = []

    tc2.value = item
    listcat3.value = []
    listcat3_new.value = []
    await getcatType(item.uid)
    listcat3.value = listcat_chk.value
    listcat3_new.value = listcat3.value
    datas.coreBox2 = item.name
    // console.log('tc1 false : ' + JSON.stringify(tc1.value));
  }
}

async function checktc3(item) {
  if (item !== tc3.value) {

    // console.log('tc1 true : ' + JSON.stringify(tc1.value));
    // } else {
    datas.coreBox3 = ''
    datas.coreBox4 = ''
    datas.coreBox5 = ''
    datas.coreBox6 = ''

    tc3.value = ""
    tc4.value = ""
    tc5.value = ""
    tc6.value = ""

    tc4_inp.value = ""
    tc5_inp.value = ""
    tc6_inp.value = ""

    listcat4.value = []
    listcat5.value = []
    listcat6.value = []

    listcat4_new.value = []
    listcat5_new.value = []
    listcat6_new.value = []

    tc3.value = item
    listcat4.value = []
    listcat4_new.value = []
    await getcatType(item.uid)
    listcat4.value = listcat_chk.value
    listcat4_new.value = listcat4.value
    datas.coreBox3 = item.name
    // console.log('tc1 false : ' + JSON.stringify(tc1.value));
  }
}

async function checktc4(item) {
  if (item !== tc4.value) {

    // console.log('tc1 true : ' + JSON.stringify(tc1.value));
    // } else {
    datas.coreBox4 = ''
    datas.coreBox5 = ''
    datas.coreBox6 = ''

    tc4.value = ""
    tc5.value = ""
    tc6.value = ""

    tc5_inp.value = ""
    tc6_inp.value = ""

    listcat5.value = []
    listcat6.value = []

    listcat5_new.value = []
    listcat6_new.value = []

    tc4.value = item
    listcat5.value = []
    listcat5_new.value = []
    await getcatType(item.uid)
    listcat5.value = listcat_chk.value
    listcat5_new.value = listcat5.value
    datas.coreBox4 = item.name
    // console.log('tc1 false : ' + JSON.stringify(tc1.value));
  }
}

async function checktc5(item) {
  if (item !== tc5.value) {

    // console.log('tc1 true : ' + JSON.stringify(tc1.value));
    // } else {
    datas.coreBox5 = ''
    datas.coreBox6 = ''

    tc5.value = ""
    tc6.value = ""

    tc6_inp.value = ""

    listcat6.value = []
    listcat6_new.value = []

    tc5.value = item
    listcat6.value = []
    listcat6_new.value = []
    await getcatType(item.uid)
    listcat6.value = listcat_chk.value
    listcat6_new.value = listcat6.value
    datas.coreBox5 = item.name
    // console.log('tc1 false : ' + JSON.stringify(tc1.value));
  }
}

function checktc6(item) {
  if (item !== tc6.value) {

    // console.log('tc1 true : ' + JSON.stringify(tc1.value));
    // } else {
    datas.coreBox6 = ''
    tc6.value = ""
    tc6.value = item
    datas.coreBox6 = item.name
    // console.log('tc1 false : ' + JSON.stringify(tc1.value));
  }
}


const loadDataAllCatApi = async () => {
  // await new Promise((res) => setTimeout(res, 500));
  let data = "";
  data = {
    uid: "",
  };
  const payload = data;
  // const response = await store.dispatch("categorylistPrintFetch", payload);
  const response = await storec.categorylistPrintFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcat_all.value = response.data.data;
        await loadDataOrderCatApi()
      }
    }
  }
};

const loadDataOrderCatApi = async (val) => {
  listcat_ordernew.value = []
  listcat_order.value = []
  let data = "";
  let list = []
  data = {
    index: 0,
    size: '100',
    shopUid: key.uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storeco.shopCatOrderListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        if (list.length === 0) {
          // console.log("no data");
          await addOrderCat()
        } else {
          let listcat = list[0].catOrders
          let listcat2 = Object.values(listcat)
          if (listcat2.length === listcat_all.value.length) {
            // console.log("listcat2 === listcat_all");
            listcat_ordernew.value = listcat2
            // console.log("listcat_new : " + JSON.stringify(listcat_new.value));
          } else {
            if (listcat2.length < listcat_all.value.length) {
              // console.log("listcat2 < listcat_all");
              // console.log("listcat z : " + JSON.stringify(listcat2));
              const arr = listcat2
              for (let i = 0; i < listcat_all.value.length; i++) {
                if (!catExists(listcat2, listcat_all.value[i].uid)) {
                  arr.push(listcat_all.value[i].uid);
                }
              }
              // console.log('arr : ' + JSON.stringify(arr));
              listcat_order.value = arr
              catorder_detail.value = {
                uid: list[0].uid,
                version: list[0].version,
                catOrders: {},
                shopUid: '',
              }
              await updateOrderCat()
            } else {
              // console.log("listcat2 > listcat_all");
              listcat_ordernew.value = listcat2
            }
          }
        }
        listcat.value = list
      }
    }
  }
}




function catExists(arr, uid) {
  return arr.some(function (el) {
    return el === uid;
  });
}



function clearItem() {
  catorder_detail.value = {
    uid: '',
    version: '',
    catOrders: {},
    shopUid: '',
  }
}

function filterCat(str) {
  let side = ''
  let name = listcat_all.value.filter((c) => c.uid === str);
  if (name.length > 0) {
    side = name[0].name
  }
  return side
}


async function saveOrderItems() {
  clearItem()
  listcat_order.value = sortable.value.toArray()
  catorder_detail.value = {
    uid: listcat.value[0].uid,
    version: listcat.value[0].version,
    catOrders: {},
    shopUid: '',
  }
  // console.log('catorder_uid : ' + JSON.stringify(catorder_detail.value));
  await updateOrderCat()
}



async function addOrderCat() {
  var keyArray = listcat_all.value.map(function (item) { return item["uid"]; });
  // console.log('keyArray : ' + JSON.stringify(keyArray));
  let obj = {};
  let ind = 1;
  for (const object of keyArray) {
    obj[ind] = object;
    ind += 1;
  }
  let data = {
    uid: '',
    version: '',
    catOrders: obj,
    shopUid: '',
  }
  const payload = data
  payload.uid = ''
  payload.shopUid = key.uid
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storeco.shopCatOrderAddFetch(payload);
  // console.log('addOrderCat : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItem()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataOrderCatApi()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateOrderCat() {
  let obj = {};
  let ind = 1;
  for (const object of listcat_order.value) {
    obj[ind] = object;
    ind += 1;
  }
  let data = {
    uid: catorder_detail.value.uid,
    version: catorder_detail.value.version,
    catOrders: obj,
    shopUid: key.uid,
  }
  const payload = data
  // console.log('payload : ' + JSON.stringify(payload));
  const response = await storeco.shopCatOrderUpdateFetch(payload);
  // console.log('updateOrderCat : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItem()
        swal("บันทึกสำเร็จ", "", "success");
        loadDataOrderCatApi()
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

onMounted(() => {
  updateWidths();
  window.addEventListener('resize', updateWidths);
  if (document.getElementById("example1")) {
    var example1 = document.getElementById('example1');
    sortable.value = Sortable.create(example1, {
      animation: 150,
      ghostClass: 'ghost',
      sort: true,
    });
  }

  // let scrollCb;
  // window.addEventListener('scroll', scrollCb = async () => {
  //   if (loadMoreStatus.value == 'idle' && list.value.getBoundingClientRect().bottom < window.innerHeight) {
  //     // loadMoreStatus.value = 'loading';
  //     if (dataList.value.length <= 20) {
  //       console.log("data : " + dataList.value.length);
  //       const scrollContainer = list.value;
  //       scrollContainer.scrollTo(0, 0);
  //     }

  //     if (typesNew.value.length > 0) {
  //       loadMoreStatus.value = "loading";
  //       fetchData();
  //     }
  //   }
  // });
  // scrollCb();



  if (document.getElementById("choices-month")) {
    var month = document.getElementById("choices-month");
    setTimeout(function () {
      new Choices(month);
    }, 1);

    // eslint-disable-next-line no-unused-vars
    var d = new Date();
    var monthArray = new Array();
    monthArray[0] = "January";
    monthArray[1] = "February";
    monthArray[2] = "March";
    monthArray[3] = "April";
    monthArray[4] = "May";
    monthArray[5] = "June";
    monthArray[6] = "July";
    monthArray[7] = "August";
    monthArray[8] = "September";
    monthArray[9] = "October";
    monthArray[10] = "November";
    monthArray[11] = "December";
    for (let m = 0; m <= 11; m++) {
      var optn = document.createElement("OPTION");
      optn.text = monthArray[m];
      // server side month start from one\
      optn.value = m + 1;
      // if june selected
      if (m == 1) {
        optn.selected = true;
      }
      month.options.add(optn);
    }
  }

  if (document.getElementById("choices-day")) {
    var day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day);
    }, 1);

    for (let y = 1; y <= 31; y++) {
      var optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }

  if (document.getElementById("choices-year")) {
    var year = document.getElementById("choices-year");
    setTimeout(function () {
      new Choices(year);
    }, 1);

    for (let y = 1900; y <= 2020; y++) {
      var optn2 = document.createElement("OPTION");
      optn2.text = y;
      optn2.value = y;

      if (y == 2020) {
        optn.selected = true;
      }

      year.options.add(optn2);
    }
  }

  // const scrollContent = document.getElementsByClassName("list overflow-auto")
  if (document.getElementById("inp")) {
    document.getElementById("inp").addEventListener("input", (e) => {
      if (e.currentTarget.value === "") {
        textt.value = "";
        loadMoreStatus.value = "idle";
        dataList.value = [];
        page_s.value = 0;
        page_e.value = 20;
      }
    });
  }


});

onUpdated(() => {
  updateText()
  updateWidths();
});
</script>

<style lang="scss" scoped>
td {
  .auto-input-search {
    appearance: none;
    background-color: #fff;
    margin-bottom: 0 !important;
  }

  .data-list {
    position: absolute !important;
    max-height: 200px;
    width: 100% !important;
    overflow-y: auto !important;
  }
}

.cs-auto-input {
  background-color: #fff0ee !important;
  width: 100%;

  .auto-input-search {
    appearance: none;
    background-color: #fff;
    margin-bottom: 0 !important;
  }

  .data-list {
    position: absolute;
    z-index: 100;
    background-color: white;
    box-shadow: 1px 1px 4px rgba($color: #000000, $alpha: 0.2);
    border-radius: 0 0 5px 5px;
    border-top: none;
    font-family: sans-serif;
    padding: 8px;
    max-height: 340px;
    overflow-y: auto;

    li {
      list-style: none;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: pointer;
    }

    li:hover,
    li:focus {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
  }

  option {
    background-color: white;
    padding: 4px;
    color: blue;
    margin-bottom: 1px;
    font-size: 18px;
    cursor: pointer;
  }

  option:hover,
  .active {
    background-color: lightblue;
  }
}

/* infinite scroll container */
#load-more {
  text-align: center;
  color: #999;
}

/* loading spinner */
#load-more.loading::before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border: solid 2px gray;
  animation: spin .25s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

/* no more data notif */
#load-more.no-more::before {
  content: 'No more results';
}

/* status helper */
#status {
  position: fixed;
  right: 1rem;
  top: 1rem;
  background: #f003;
  padding: .2rem .5rem;
  border-radius: 4px;
}

label::after {
  display: inline-block;
  transform: translateX(calc(-100% - 5px));
}

* {
  border-radius: 0 !important;
}

/* ghostClass */
.ghost {
  opacity: .5;
  background: #C8EBFB;
}

.list-group-item {
  padding: 10px;
}

.list-group-item {
  cursor: grab;
}

.list-group-item:hover {
  z-index: 0;
}
</style>
