<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.mbankp') }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>
                </div>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th style="min-width: 70px;">#</th>
                      <th style="min-width: 120px;">{{ $t('content.mbankpshort') }}</th>
                      <th style="min-width: 250px;">{{ $t('content.mbankname') }}</th>
                      <th style="min-width: 120px;">{{ $t('content.cbankno') }}</th>
                      <th style="min-width: 120px;">{{ $t('content.amnmoneylimit') }}</th>
                      <th style="min-width: 240px;">{{ $t('content.note') }}</th>
                      <th colspan="4" class="align-top text-center">{{ $t('content.amnchannel') }}</th>
                      <th>{{ $t('content.act') }}</th>
                    </tr>
                    <tr>
                      <th colspan="6"></th>
                      <th class="align-top text-center">
                        {{ $t("content.fo_mobile") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.fo_web") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.bo_mobile") }}
                      </th>
                      <th class="align-top text-center">
                        {{ $t("content.bo_web") }}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="filtershortName" type="text" class="mb-0" :label="lbShort" />
                      </td>
                      <td class="text-sm font-weight-normal" colspan="3">
                        <div class="card flex justify-content-center">
                          <Dropdown v-model="filterBankUid" :options="listbank" optionLabel="uid"
                            placeholder="Select a Bank" class="w-full md:w-auto">
                            <template #value="slotProps">
                              <div v-if="slotProps.value" class="both">
                                <div class="bank-logo">
                                  <img :src="slotProps.value.thumb" style="background-position:0 ; width: 18px" />
                                </div>
                                &nbsp;&nbsp;
                                <div>{{ slotProps.value.nameTh }} ( {{ slotProps.value.nameEn }} )</div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>

                            <template #option="slotProps">
                              <div class="both">
                                <div class="bank-logo">
                                  <img :src="slotProps.option.thumb" style="background-position:0 ; width: 18px" />
                                </div>&nbsp;&nbsp;
                                <div>{{ slotProps.option.nameTh }} ( {{ slotProps.option.nameEn }} )</div>
                              </div>
                            </template>
                          </Dropdown>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal" colspan="3">
                        <material-input v-model="filteracctNumber" type="text" class="mb-0"
                          :label="$t('content.cbankno')" @input="onlyDigits_filteracctNumber" />
                      </td>
                      <td colspan="2" class="text-sm font-weight-normal">
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="datas.addNew">
                      <td class="text-sm font-weight-normal">#</td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="shopbank_detail.shortName" type="text" class="mb-0" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <div class="card flex justify-content-center">
                          <Dropdown v-model="selectedBankAdd" :options="listbank" optionLabel="nameTh"
                            placeholder="Select a Bank" class="w-full md:w-auto mb-0 ">
                            <template #value="slotProps">
                              <div v-if="slotProps.value" class="both">
                                <div class="bank-logo">
                                  <img :src="slotProps.value.thumb" style="background-position:0 ; width: 18px" />
                                </div>
                                &nbsp;&nbsp;
                                <div>{{ slotProps.value.nameTh }} ( {{ slotProps.value.nameEn }} )</div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>

                            <template #option="slotProps">
                              <div class="both">
                                <div class="bank-logo">
                                  <img :src="slotProps.option.thumb" style="background-position:0 ; width: 18px" />
                                </div>&nbsp;&nbsp;
                                <div>{{ slotProps.option.nameTh }} ( {{ slotProps.option.nameEn }} )</div>
                              </div>
                            </template>
                          </Dropdown>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="shopbank_detail.acctNumber" type="text" class="mb-0"
                          @input="onlyDigits" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <InputNumber v-model="shopbank_detail.maxLimit" :minFractionDigits="0" :maxFractionDigits="2"
                          class="mb-0" autocomplete="off" @focus="$event.target.select()" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="shopbank_detail.note" type="text" class="mb-0" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_MOBILE" :checked="FO_MOBILE" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_WEB" :checked="FO_WEB" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_MOBILE" :checked="BO_MOBILE" />
                      </td>
                      <td class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_WEB" :checked="BO_WEB" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="shopbank_detail.shortName.trim() === '' || selectedBankAdd === '' ||
                            shopbank_detail.acctNumber === '' || shopbank_detail.maxLimit === '' || shopbank_detail.maxLimit === null || shopbank_detail.maxLimit === '0' || parseFloat(shopbank_detail.maxLimit) === 0 ||
                            shopbank_detail.note.trim() === ''" @click="saveNewItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelSaveNewItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>


                    <tr v-for="(row, index) in list" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal">
                        <material-input v-model="shopbank_detail.shortName" type="text" class="mb-0" />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal">
                        <div class="card flex justify-content-center">
                          <Dropdown v-model="selectedBankAdd" :options="listbank" optionLabel="nameTh"
                            placeholder="Select a Bank" class="w-full md:w-auto mb-0 ">
                            <template #value="slotProps">
                              <div v-if="slotProps.value" class="both">
                                <div class="bank-logo">
                                  <img :src="slotProps.value.thumb" style="background-position:0 ; width: 18px" />
                                </div>
                                &nbsp;&nbsp;
                                <div>{{ slotProps.value.nameTh }} ( {{ slotProps.value.nameEn }} )</div>
                              </div>
                              <span v-else>
                                {{ slotProps.placeholder }}
                              </span>
                            </template>

                            <template #option="slotProps">
                              <div class="both">
                                <div class="bank-logo">
                                  <img :src="slotProps.option.thumb" style="background-position:0 ; width: 18px" />
                                </div>&nbsp;&nbsp;
                                <div>{{ slotProps.option.nameTh }} ( {{ slotProps.option.nameEn }} )</div>
                              </div>
                            </template>
                          </Dropdown>
                        </div>
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal">
                        <material-input v-model="shopbank_detail.acctNumber" type="text" class="mb-0"
                          @input="onlyDigits" />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal">
                        <InputNumber v-model="shopbank_detail.maxLimit" :minFractionDigits="0" :maxFractionDigits="2"
                          class="mb-0" autocomplete="off" @focus="$event.target.select()" />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal">
                        <material-input v-model="shopbank_detail.note" type="text" class="mb-0" />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_MOBILE" :checked="FO_MOBILE" />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="FO_WEB" :checked="FO_WEB" />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_MOBILE" :checked="BO_MOBILE" />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox v-model="BO_WEB" :checked="BO_WEB" />
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal">
                        {{ row.shortName }}
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal">
                        <span>
                          <div class="both">
                            <div class="bank-logo">
                              <img :src="row.bankDetail.thumb" style="background-position:0 ; width: 18px" />
                            </div>&nbsp;&nbsp;
                            <div>{{ row.bankDetail.nameTh }} ( {{ row.bankDetail.nameEn }} )</div>
                          </div>
                        </span>
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal">
                        {{ row.acctNumber }}
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal">
                        {{ formatNumberString(row.maxLimit) }}
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal">
                        {{ row.note }}
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.FO_MOBILE" disabled />
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.FO_WEB" disabled />
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.BO_MOBILE" disabled />
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal text-center">
                        <material-checkbox :checked="row.BO_WEB" disabled />
                      </td>
                      <td v-if="row.uid === shopbank_detail.uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="shopbank_detail.shortName.trim() === '' || selectedBankAdd === '' ||
                            shopbank_detail.acctNumber === '' || shopbank_detail.maxLimit === '' || shopbank_detail.maxLimit === null || shopbank_detail.maxLimit === '0' || parseFloat(shopbank_detail.maxLimit) === 0 ||
                            shopbank_detail.note.trim() === ''" @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                      <td v-if="row.uid !== shopbank_detail.uid" class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="list.length === 0">
                      <td colspan="10" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="15" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="list.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      @click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"
import moment from 'moment'
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";


import axios from "axios";
import noimg from "@/assets/img/noimg.png"
import * as st from "@/config/setting.js";
import { useBankStore } from '@/stores/bank'
import { useShopBankStore } from '@/stores/shopbank'
import { useCloseBankStore } from '@/stores/closebank'

const { t } = useI18n()
const swal = inject('$swal')

const storeb = useBankStore()
const storesb = useShopBankStore()
const storecb = useCloseBankStore()

const datas = reactive({
  addNew: false,
  rowEdit: false,
  demo1: [
    { items: "ประเภท1.1" },
    { items: "ประเภท1.2" },
    { items: "ประเภท1.3" },
    { items: "ประเภท1.4" },
    { items: "ประเภท1.5" },
    { items: "ประเภท1.6" }
  ]
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const lbShort = computed(() => t("content.mbankpshort"))

const list = ref([]);

const listbank = ref([]);
const lb = ref([]);
const urls = ref([]);

const selectedBankAdd = ref('');

const filterBankUid = ref('');
const filtershortName = ref('');
const filteracctNumber = ref('');

const shopbank_detail = ref({
  uid: '',
  bankUid: '',
  shopUid: '',
  shortName: '',
  acctNumber: '',
  maxLimit: '',
  note: '',
  version: '',
  channels: []
})

const channels = ref([]);
const FO_MOBILE = ref(false);
const FO_WEB = ref(false);
const BO_MOBILE = ref(false);
const BO_WEB = ref(false);

const onClickHandler = (page) => {
  updatePagination()
};
const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();
const key = JSON.parse(localStorage.getItem("shop_seleted"));

const loadBankMasterApi = async (val) => {

  let data = "";
  lb.value = [];
  urls.value = []


  data = {
    index: "0",
    size: 100,
    nameTh: '',
    nameEn: '',
  };
  const payload = data;
  // const response = await store.dispatch("bankListFetch", payload);
  const response = await storeb.bankListFetch(payload);
  // console.log("loadBankMasterApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listbank.value = response.data.data.list;
        loadDataApi();
        listbank.value.forEach(function (obj) {
          obj.thumb = noimg;
          obj.file = null;
          obj.data = null;

        });
        // console.log("lb : " + JSON.stringify(lb));
        for (let i = 0; i < listbank.value.length; i++) {
          // console.log("logoUid : " + lb[i].logoUid);
          if (listbank.value[i].logoUid !== "0") {
            urls.value.push(String(st.url_api + "/doc-svc/document/info/" + listbank.value[i].logoUid));
            // urls.value.push(axios.get((st.url_document + "/doc-svc/document/info/" + lb[i].logoUid)));
          }
        }
        // console.log("urls : " + JSON.stringify(urls.value));
        if (urls.value.length > 0) {
          lb.value = listbank.value
          await getFileLoco()
          listbank.value = lb.value
        }
        // listbank.value = lb.value
        // totalRows.value = response.data.data.ext.total
        // totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
};

loadBankMasterApi()

const loadDataApi = async (val) => {
  clearItem()
  if (currentPage.value !== 1) {
    if (list.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  let data = "";
  let listdata = []
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    bankUid: filterBankUid.value.uid,
    shortName: filtershortName.value,
    acctNumber: String(filteracctNumber.value),
    shopUid: key.uid
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  // console.log("load payload : " + JSON.stringify(data));
  const response = await storesb.shopbankListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata = response.data.data.list;
        listdata.map(function (x) {
          const lf = filterData(x.bankUid, listbank.value)
          // console.log("lf : " + JSON.stringify(lf));
          x.bankDetail = lf[0]
          x.FO_MOBILE = false
          x.FO_WEB = false
          x.BO_MOBILE = false
          x.BO_WEB = false
          return x
        });
        for (let i = 0; i < listdata.length; i++) {
          if (listdata[i].channels.includes('FO_MOBILE')) {
            listdata[i].FO_MOBILE = true
          }
          if (listdata[i].channels.includes('FO_WEB')) {
            listdata[i].FO_WEB = true
          }
          if (listdata[i].channels.includes('BO_MOBILE')) {
            listdata[i].BO_MOBILE = true
          }
          if (listdata[i].channels.includes('BO_WEB')) {
            listdata[i].BO_WEB = true
          }
        }
        list.value = listdata
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }


};




async function updatePagination() {
  let listdata = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    bankUid: filterBankUid.value.uid,
    shortName: filtershortName.value,
    acctNumber: String(filteracctNumber.value),
    shopUid: key.uid
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  const response = await storesb.shopbankListFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdata = response.data.data.list;
        listdata.map(function (x) {
          const lf = filterData(x.bankUid, listbank.value)
          // console.log("lf : " + JSON.stringify(lf));
          x.bankDetail = lf[0]
          x.FO_MOBILE = false
          x.FO_WEB = false
          x.BO_MOBILE = false
          x.BO_WEB = false
          return x
        });
        for (let i = 0; i < listdata.length; i++) {
          if (listdata[i].channels.includes('FO_MOBILE')) {
            listdata[i].FO_MOBILE = true
          }
          if (listdata[i].channels.includes('FO_WEB')) {
            listdata[i].FO_WEB = true
          }
          if (listdata[i].channels.includes('BO_MOBILE')) {
            listdata[i].BO_MOBILE = true
          }
          if (listdata[i].channels.includes('BO_WEB')) {
            listdata[i].BO_WEB = true
          }
        }
        list.value = listdata
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }
  }
}


function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function filterData(str, matchArr) {
  return matchArr.filter((c) => c.uid === str);
}


async function getFileLoco() {
  let file_L = []
  const keyA = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
  const accessToken = keyA.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.value.map((url) => axios.get(url));
  await axios.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            const lf = filterFile(file_L[i].data.data.uid, lb.value)
            lf[0].thumb = data
            lf[0].file = file
            lf[0].data = data
          } else {
            // console.log('no dataFile ')
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}


function filterFile(str, matchArr) {
  //  return matchArr.filter((c) => c.data.data.uid === str);
  return matchArr.filter((c) => c.logoUid === str);
}

async function onlyDigits_filteracctNumber() {

  if (filteracctNumber.value !== "") {
    if (filteracctNumber.value.match(/^[0-9]+$/)) {
      filteracctNumber.value = filteracctNumber.value.match(/^[0-9]+$/);

    } else {
      filteracctNumber.value = filteracctNumber.value.slice(0, -1);
    }
  } else {
    filteracctNumber.value = "";
  }
}

async function onlyDigits() {

  if (shopbank_detail.value.acctNumber !== "") {
    if (shopbank_detail.value.acctNumber.match(/^[0-9]+$/)) {
      shopbank_detail.value.acctNumber = shopbank_detail.value.acctNumber.match(/^[0-9]+$/);

    } else {
      shopbank_detail.value.acctNumber = shopbank_detail.value.acctNumber.slice(0, -1);
    }
  } else {
    shopbank_detail.value.acctNumber = "";
  }
}


function clearItem() {
  datas.addNew = false
  datas.rowEdit = false
  selectedBankAdd.value = ''
  shopbank_detail.value = {
    uid: '',
    bankUid: '',
    shopUid: '',
    shortName: '',
    acctNumber: '',
    maxLimit: '',
    note: '',
    version: '',
    channels: []
  }
  FO_MOBILE.value = false
  FO_WEB.value = false
  BO_MOBILE.value = false
  BO_WEB.value = false
  channels.value = []
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      datas.addNew = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.addNew = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success")
      datas.addNew = false
      addshopbank()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      datas.addNew = false
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = false
      clearItem()
    } else if (result.dismiss == 'cancel') {
      datas.addNew = true
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItem()
      selectedBankAdd.value = item.bankDetail
      shopbank_detail.value = {
        uid: item.uid,
        bankUid: item.bankUid,
        shortName: item.shortName,
        acctNumber: item.acctNumber,
        maxLimit: item.maxLimit,
        note: item.note,
        version: item.version,
      }
      FO_MOBILE.value = item.FO_MOBILE
      FO_WEB.value = item.FO_WEB
      BO_MOBILE.value = item.BO_MOBILE
      BO_WEB.value = item.BO_WEB
      datas.rowEdit = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.rowEdit = false
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success")
      datas.rowEdit = false
      if (shopbank_detail.value.uid !== "" && shopbank_detail.value.version !== "") {
        updateshopbank()
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      datas.rowEdit = false
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.rowEdit = false
      clearItem()
    } else if (result.dismiss == 'cancel') {
      datas.rowEdit = true
    }
  })
}


async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteshopbank(item.uid);
      }
    }
  });
}

async function addshopbank() {
  channels.value = []
  await findChannels()
  shopbank_detail.value.bankUid = selectedBankAdd.value.uid
  shopbank_detail.value.acctNumber = String(shopbank_detail.value.acctNumber)
  shopbank_detail.value.shopUid = key.uid
  shopbank_detail.value.channels = channels.value
  const payload = shopbank_detail.value;
  // console.log('addItems 1 : ' + JSON.stringify(payload));
  const response = await storesb.shopbankAddFetch(payload);
  // console.log('addItems 2 : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        await loadDataApi('add');
        await addCloseBankOpenshop(response.data.data)
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateshopbank() {
  channels.value = []
  await findChannels()
  shopbank_detail.value.bankUid = selectedBankAdd.value.uid
  shopbank_detail.value.acctNumber = String(shopbank_detail.value.acctNumber)
  shopbank_detail.value.shopUid = key.uid
  shopbank_detail.value.channels = channels.value
  const payload = shopbank_detail.value;
  // const response = await store.dispatch("unitUpdateFetch", payload);
  const response = await storesb.shopbankUpdateFetch(payload);
  // console.log('editItems : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('edit');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteshopbank(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  // const response = await store.dispatch("unitDeleteFetch", payload);
  const response = await storesb.shopbankDeleteFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addCloseBankOpenshop(item) {
  let payload = ""
  payload = {
    bankAccUid: item.uid,
    closingDateTime: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
    totalAmount: 0.00,
    bankAccName: filterBankacc(item.uid).trim()
  }
  // console.log('acb p : ' + JSON.stringify(payload));
  const response = await storecb.closeBankNewOpenshopFetch(payload);
  // console.log('acb r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // loadDataApi('update')
        // swal("บันทึกสำเร็จ", "", "success");
      } else {
        // swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function findChannels() {
  let allow = []
  if (FO_MOBILE.value) {
    allow.push('FO_MOBILE')
  }
  if (FO_WEB.value) {
    allow.push('FO_WEB')
  }
  if (BO_MOBILE.value) {
    allow.push('BO_MOBILE')
  }
  if (BO_WEB.value) {
    allow.push('BO_WEB')
  }

  channels.value = allow
}


function filterBankacc(str) {
  let name = list.value.filter((c) => c.uid === str);
  return name[0].shortName;
}

</script>

<style lang="scss" scoped>
div.both {
  display: flex;
}
</style>