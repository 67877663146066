import { createRouter, createWebHashHistory } from "vue-router";
// import { getCurrentUser } from '@/firebase'

/// pages from theme
import Default from "../views/dashboards/Default.vue";

import Settings from "../views/pages/account/Settings.vue";
import Basic from "../views/auth/signin/Basic.vue";
// import Sales from "../views/dashboards/Sales.vue";
// import Overview from "../views/pages/profile/Overview.vue";
// import Projects from "../views/pages/profile/Projects.vue";
// import Timeline from "../views/pages/projects/Timeline.vue";
// import Pricing from "../views/pages/Pricing.vue";
// import RTL from "../views/pages/Rtl.vue";
// import Charts from "../views/pages/Charts.vue";
// import Notifications from "../views/pages/Notifications.vue";
// import Kanban from "../views/applications/Kanban.vue";
// import Wizard from "../views/applications/wizard/Wizard.vue";
// import DataTables from "../views/applications/DataTables.vue";
// import Calendar from "../views/applications/Calendar.vue";
// import NewProduct from "../views/ecommerce/products/NewProduct.vue";
// import EditProduct from "../views/ecommerce/products/EditProduct.vue";
// import ProductPage from "../views/ecommerce/products/ProductPage.vue";
// import OrderDetails from "../views/ecommerce/orders/OrderDetails";
// import OrderList from "../views/ecommerce/orders/OrderList";
// import NewUser from "../views/pages/users/NewUser.vue";
// import Settings from "../views/pages/account/Settings.vue";
// import Billing from "../views/pages/account/Billing.vue";
// import Invoice from "../views/pages/account/Invoice.vue";
// import Widgets from "../views/pages/Widgets.vue";
// import Basic from "../views/auth/signin/Basic.vue";
// import Cover from "../views/auth/signin/Cover.vue";
// import Illustration from "../views/auth/signin/Illustration.vue";
// import ResetCover from "../views/auth/reset/Cover.vue";
// import SignupCover from "../views/auth/signup/Cover.vue";

/// project pages
/// master
import Mbank from "../views/master/banks.vue";
import Mwarn from "../views/master/warning.vue";
import Millegal from "../views/master/illegalUnits.vue";
// import Mgroup from "../views/master/assetGroup.vue";
import Masset from "../views/master/assetType.vue";
import Mprefix from "../views/master/prefixs.vue";
import Maddress from "../views/master/address.vue";
import Mgrade from "../views/master/customerGrade.vue";
// import Mdoc from "../views/master/docType.vue";
// import Munit from "../views/master/units.vue";
import Mpawn from "../views/master/bankPawn.vue";
import Mcash from "../views/master/cashAccount.vue";
import Mtransaction from "../views/master/transactionType.vue";
// import Mbanknote from "../views/master/bankNote.vue";
// import Mbiz from "../views/master/businessType.vue";

/// generals
import Gdash from "../views/generals/dashboards.vue";
import Gtransaction from "../views/generals/transaction.vue";
import Goverall from "../views/generals/overall.vue";
import Gjor6 from "../views/generals/j6.vue";
// import Gsplitasset from "../views/generals/splitAssets.vue";
// import Ghistory from "../views/generals/history.vue";
// import Gticketchain from '../views/generals/ticketChain.vue';
// import Gover from "../views/generals/unitOverdue.vue";
// import Gdeferitems from "../views/generals/deferItems.vue";
// import Gseize from "../views/generals/seize.vue";

/// finances
// import Ffinance from "../views/finances/finance.vue";
// import Fcash from "../views/finances/cashMovement.vue";
// import Fmoney from "../views/finances/moneyManage.vue";
// import Fdrawer from "../views/finances/cashDrawer.vue";

/// customers
// import Gdeposit from "../views/customers/deposit.vue";
// import Gcustomerinfo from "../views/customers/customerInfo.vue";
// import Gcustomerport from '../views/customers/portfolio.vue';

/// stocks
// import STlocation from "../views/stocks/locations.vue";
import STlocation from "../views/stocks/locations2.vue";
// import STstock from "../views/stocks/stock.vue";

/// Reports
// import RPall from "../views/reports/reportAll.vue";
// import RPday from "../views/reports/reportDay.vue";
// import RPpawn from "../views/reports/reportPawn.vue";
// import RPprofit from "../views/reports/reportProfit.vue";
// import RPaddcap from "../views/reports/reportAddCap.vue";
// import RPreducecap from "../views/reports/reportReduceCap.vue";
// import RPredeem from "../views/reports/reportRedeem.vue";
// import RPcancel from "../views/reports/reportCancel.vue";
// import RPdefer from "../views/reports/reportDefer.vue";
// import RPtop20 from "../views/reports/reportTop20.vue";
// import RPtransaction from "../views/reports/reportTransactions.vue";
// import EPoverdueitems from "../views/reports/exportOverdueItems.vue";

/// settings
import Sopen from "../views/settings/openBranch.vue";
import Spre from "../views/settings/prePrint.vue";
import Sinterest from "../views/settings/interest.vue";
// import Sstock from "../views/settings/stock.vue";
// import Sprice from "../views/settings/goldPrice.vue";
import Mholiday from "../views/settings/holidays.vue";
import Srole from "../views/settings/securityRole.vue";
import Ssetup from "../views/settings/securitySetup.vue";
import Suser from "../views/settings/users.vue";
import Ssetbranch from "../views/settings/setBranch.vue";

const routes = [
  // main redirect page
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  // old redirect page
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
    meta: {
      requiresAuth: true,
    },
  },
  // auth redirect page
  {
    path: "/generals/dashboard",
    name: "Dashboard",
    component: Gdash,
    meta: {
      requiresAuth: true,
    },
  },
  // generals
  {
    path: "/generals/transaction_list",
    name: "TransactionList",
    component: Gtransaction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/ticket",
    name: "TransactionOverall",
    component: Goverall,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/jor_six",
    name: "Jor6",
    component: Gjor6,
  },
  // {
  //   path: "/generals/split_assets",
  //   name: "SplitAssets",
  //   component: Gsplitasset,
  // },
  // {
  //   path: "/generals/history",
  //   name: "History",
  //   component: Ghistory,
  // },
  // {
  //   path: "/generals/ticket_chain",
  //   name: "TicketChain",
  //   component: Gticketchain,
  // },
  // {
  //   path: "/generals/unit_overdue",
  //   name: "UnitOverdue",
  //   component: Gover,
  // },
  // {
  //   path: "/generals/defer_items",
  //   name: "DeferItems",
  //   component: Gdeferitems,
  // },
  // {
  //   path: "/generals/seize",
  //   name: "Seize",
  //   component: Gseize,
  // },
  // finances
  // {
  //   path: "/finances/finance",
  //   name: "Finance",
  //   component: Ffinance,
  // },
  // {
  //   path: "/finances/cash_movement",
  //   name: "CashMovement",
  //   component: Fcash,
  // },
  // {
  //   path: "/finances/transactions_management",
  //   name: "TranctionsManagement",
  //   component: Fmoney,
  // },
  // {
  //   path: "/finances/cash_drawer",
  //   name: "CashDrawer",
  //   component: Fdrawer,
  // },
  // customers
  // {
  //   path: "/customers/customer_deposit",
  //   name: "CustomerDeposit",
  //   component: Gdeposit,
  // },
  // {
  //   path: "/customers/customer_info",
  //   name: "CustomerInfo",
  //   component: Gcustomerinfo,
  // },
  // {
  //   path: "/customers/customer_port",
  //   name: "CustomerPort",
  //   component: Gcustomerport,
  // },
  // stock
  {
    path: "/stocks/location",
    name: "Location",
    component: STlocation,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/stocks/stock",
  //   name: "Stock",
  //   component: STstock,
  // },
  // reports
  // {
  //   path: "/reports/report_all",
  //   name: "ReportAll",
  //   component: RPall,
  // },
  // {
  //   path: "/reports/report_day",
  //   name: "ReportDay",
  //   component: RPday,
  // },
  // {
  //   path: "/reports/report_pawn",
  //   name: "ReportPawn",
  //   component: RPpawn,
  // },
  // {
  //   path: "/reports/report_profit",
  //   name: "ReportpProfit",
  //   component: RPprofit,
  // },
  // {
  //   path: "/reports/report_addcap",
  //   name: "ReportAddCap",
  //   component: RPaddcap,
  // },
  // {
  //   path: "/reports/report_reducecap",
  //   name: "ReportReduceCap",
  //   component: RPreducecap,
  // },
  // {
  //   path: "/reports/report_redeem",
  //   name: "ReportRedeem",
  //   component: RPredeem,
  // },
  // {
  //   path: "/reports/report_cancel",
  //   name: "ReportCancel",
  //   component: RPcancel,
  // },
  // {
  //   path: "/reports/report_defer",
  //   name: "ReportDefer",
  //   component: RPdefer,
  // },
  // {
  //   path: "/reports/report_top_20",
  //   name: "ReportTop20",
  //   component: RPtop20,
  // },
  // {
  //   path: "/reports/report_transaction",
  //   name: "ReportTransaction",
  //   component: RPtransaction,
  // },
  // {
  //   path: "/reports/export_overdue_items",
  //   name: "ExportOverdueItems",
  //   component: EPoverdueitems,
  // },
  // master
  {
    path: "/master/bank",
    name: "Bank",
    component: Mbank,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/warning",
    name: "Warning",
    component: Mwarn,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/illegal_assets",
    name: "IllegalAssets",
    component: Millegal,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/master/asset_group",
  //   name: "AssetGroup",
  //   component: Mgroup,
  // },
  {
    path: "/master/asset_type",
    name: "AssetType",
    component: Masset,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/prefix",
    name: "Prefix",
    component: Mprefix,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/address",
    name: "Address",
    component: Maddress,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/customer_grade",
    name: "CustomerGrade",
    component: Mgrade,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/master/document_type",
  //   name: "DocumentType",
  //   component: Mdoc,
  // },
  // {
  //   path: "/master/units",
  //   name: "Units",
  //   component: Munit,
  // },
  {
    path: "/master/pawn_bank",
    name: "PawnBank",
    component: Mpawn,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/cash_account",
    name: "CashAccount",
    component: Mcash,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/transaction_type",
    name: "FinancialTransactionType",
    component: Mtransaction,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/master/bank_note",
  //   name: "BankNote",
  //   component: Mbanknote,
  // },
  // {
  //   path: "/master/business_type",
  //   name: "BusinessType",
  //   component: Mbiz,
  // },
  // settings
  {
    path: "/settings/open_branch",
    name: "OpenBranch",
    component: Sopen,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/setting_branch",
    name: "SetBranch",
    component: Ssetbranch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/pre_print_and_running",
    name: "PrePrint",
    component: Spre,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/interest",
    name: "Interest",
    component: Sinterest,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/settings/stock",
  //   name: "SetStock",
  //   component: Sstock,
  // },
  // {
  //   path: "/settings/gold_price",
  //   name: "GoldPrice",
  //   component: Sprice,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/settings/holidays",
    name: "Holidays",
    component: Mholiday,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/security_setup",
    name: "SecurityRole",
    component: Srole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/security_setup",
    name: "SecuritySetup",
    component: Ssetup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user",
    name: "SetUser",
    component: Suser,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/dashboards/sales",
  //   name: "Sales",
  //   component: Sales,
  // },
  // {
  //   path: "/pages/profile/overview",
  //   name: "Profile Overview",
  //   component: Overview,
  // },
  // {
  //   path: "/pages/profile/projects",
  //   name: "All Projects",
  //   component: Projects,
  // },
  // {
  //   path: "/pages/projects/timeline",
  //   name: "Timeline",
  //   component: Timeline,
  // },
  // {
  //   path: "/pages/pricing-page",
  //   name: "Pricing Page",
  //   component: Pricing,
  // },
  // {
  //   path: "/pages/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  // },
  // {
  //   path: "/pages/charts",
  //   name: "Charts",
  //   component: Charts,
  // },
  // {
  //   path: "/pages/widgets",
  //   name: "Widgets",
  //   component: Widgets,
  // },
  // {
  //   path: "/pages/notifications",
  //   name: "Notifications",
  //   component: Notifications,
  // },
  // {
  //   path: "/applications/kanban",
  //   name: "Kanban",
  //   component: Kanban,
  // },
  // {
  //   path: "/applications/wizard",
  //   name: "Wizard",
  //   component: Wizard,
  // },
  // {
  //   path: "/applications/data-tables",
  //   name: "Data Tables",
  //   component: DataTables,
  // },
  // {
  //   path: "/applications/calendar",
  //   name: "Calendar",
  //   component: Calendar,
  // },
  // {
  //   path: "/ecommerce/products/new-product",
  //   name: "New Product",
  //   component: NewProduct,
  // },
  // {
  //   path: "/ecommerce/products/edit-product",
  //   name: "Edit Product",
  //   component: EditProduct,
  // },
  // {
  //   path: "/ecommerce/products/product-page",
  //   name: "Product Page",
  //   component: ProductPage,
  // },
  // {
  //   path: "/ecommerce/Orders/order-details",
  //   name: "Order Details",
  //   component: OrderDetails,
  // },
  // {
  //   path: "/ecommerce/Orders/order-list",
  //   name: "Order List",
  //   component: OrderList,
  // },
  // {
  //   path: "/pages/users/new-user",
  //   name: "New User",
  //   component: NewUser,
  // },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/pages/account/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/pages/account/invoice",
  //   name: "Invoice",
  //   component: Invoice,
  // },
  {
    path: "/signin",
    name: "Signin Basic",
    component: Basic,
  },
  // {
  //   path: "/authentication/signin/cover",
  //   name: "Signin Cover",
  //   component: Cover,
  // },
  // {
  //   path: "/authentication/signin/illustration",
  //   name: "Signin Illustration",
  //   component: Illustration,
  // },
  // {
  //   path: "/authentication/reset/cover",
  //   name: "Reset Cover",
  //   component: ResetCover,
  // },
  // {
  //   path: "/authentication/signup/cover",
  //   name: "Signup Cover",
  //   component: SignupCover,
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

// router.beforeEach(async (to) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   if (requiresAuth && !await getCurrentUser()) {
//     return '/signin';
//   }
// });
// router.beforeEach(async (to) => {
router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && localStorage.getItem("FR-SDK-nvtx-admin") === null) {
    return "/signin";
  }

  // if (window.location.href.indexOf("signin") != -1) {
  //   await userStore.clearSession();
  //   console.log("url : " + window.location.href);
  //   localStorage.removeItem("shoplist");
  //   localStorage.removeItem("shop_seleted");
  // }
});

export default router;
